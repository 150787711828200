import React, { useState, useContext, useEffect } from "react";
import firebase from "firebase/compat/app";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import Loader from '../../../../components/Loader';
import { Form, Field, Input, Select } from "../../../../components/Form";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions, FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import Alert from "../../../../components/Alert";
import { superAdmins } from "../../superAdmins"
import CustomDomain from "../../../../components/APISettings/CustomDomain";
import ApiKey from "../../../../components/APISettings/ApiKey";
import OAuthSecrets from "../../../../components/APISettings/OAuthSecrets";
import ConsumerPublicKey from "../../../../components/APISettings/ConsumerPublicKey";
import SubdomainField from "../../../../components/APISettings/SubdomainField";

const APISettings = () => {
  const title = "API Settings";

  const [subdomainName, setSubdomainName] = useState({
    hasError: false,
    error: null,
    value: ".ltiaas.com",
  });

  const [zoneName, setZoneName] = useState({
    hasError: false,
    error: null,
    value: "us-central1",
  });

  const [zoneOptions, setZoneOptions] = useState([]);

  const [services, setServices] = useState({
    deepLinking: false,
    dynamicRegistration: false,
    customSubdomain: false
  });

  const changeZoneName = (e) => {
    if(!services.customSubdomain) {
      const foundZone = zoneOptions.find(z => z.value === e.value)
      const domain = foundZone ? foundZone.prefix : ".ltiaas.com";
      if(!settingsCommitted) {
        setSubdomainName({value: domain, hasError: false, error: null})
      }
    }
    setZoneName(e);
  }

  React.useEffect(() => {
    if(zoneOptions.length > 0 && !settingsCommitted) {
      changeZoneName({
        hasError: false,
        error: null,
        value: zoneOptions[0].value
      });
    }
  }, [zoneOptions])

  const [toolName, setToolName] = useState({
    hasError: false,
    error: null,
    value: "",
  });

  const [toolDescription, setToolDescription] = useState({
    hasError: false,
    error: null,
    value: "",
  });

  const [launchURL, setLaunchURL] = useState({
    hasError: false,
    error: null,
    value: "",
  });

  const [deepLinkingURL, setDeepLinkingURL] = useState({
    hasError: false,
    error: null,
    value: "",
  });

  const [logoURL, setLogoURL] = useState({
    hasError: false,
    error: null,
    value: "",
  });

  const [autoActivate, setAutoActivate] = useState({
    hasError: false,
    error: null,
    value: "false",
  });

  const [serviceURL, setServiceURL] = useState({
    hasError: false,
    error: null,
    value: "",
  });

  const [account, setAccount] = useState({});
  const [planType, setPlanType] = useState("provider");
  const [deploymentMethod, setDeploymentMethod] = useState("kubernetes");

  const [errorMessage, setErrorMessage] = useState(null);
  const [deployErrorMessage, setDeployErrorMessage] = useState(null);
  const [deploySuccessMessage, setDeploySuccessMessage] = useState(null);

  const [inSubmit, setInSubmit] = useState(false);
  const [inDeploy, setInDeploy] = useState(false);

  const [apiKey, setApiKey] = useState(null);
  const [settingsCommitted, setSettingsCommitted] = useState(false);
  const [deployNeeded, setDeployNeeded] = useState(false);

  const [dedicatedCPUProvisioning, setDedicatedCPUProvisioning] = useState(false);
  const [apiErrorEmail, setApiErrorEmail] = useState({
    hasError: false,
    error: null,
    value: "",
  });
  const [inTestEmail, setInTestEmail] = useState(false);
  const [emailTestMessage, setEmailTestMessage] = useState(null)

  const { userData, authUser } = useContext(AuthContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  function validateForm() {
    const foundZone = zoneOptions.find(z => z.value === zoneName.value)
    const domain = foundZone ? foundZone.prefix : ".ltiaas.com";
    return (
      subdomainName.hasError ||
      subdomainName.value === "" ||
      subdomainName.value === domain ||
      toolName.hasError ||
      toolName.value === "" ||
      toolDescription.hasError ||
      (toolDescription.value === "" && planType === "provider")||
      launchURL.hasError ||
      serviceURL.hasError ||
      launchURL.value === "" ||
      deepLinkingURL.hasError ||
      logoURL.hasError ||
      autoActivate.hasError ||
      inSubmit
    );
  }

  const updateAPISettings = async () => {
    setInSubmit(true);
    const settings = {
      toolName: toolName.value,
      toolDescription: toolDescription.value,
      launchURL: launchURL.value,
      serviceURL: serviceURL.value,
      deepLinkingURL: deepLinkingURL.value,
      logoURL: logoURL.value,
      autoActivate: autoActivate.value,
      apiErrorEmail: apiErrorEmail.value,
      lastSave: firebase.firestore.Timestamp.now()
    };

    try{
      const subdomains = FirebaseAuth.firestore()
        .collection("subdomains")
        .where("subdomainName", "==", subdomainName.value);
      const querySnapshot = await subdomains.get();
      querySnapshot.forEach((doc) => {
        if (doc.id !== userData.currentAccount.id) {
          setSettingsCommitted(false);
          throw new Error("Sorry, this subdomain is reserved. Please choose a different one.");
        }
      });

      const collectionRef = FirebaseAuth.firestore()
        .collection("accounts")
        .doc(userData.currentAccount.id)
        .collection("api_settings")
        .doc("private");
      await collectionRef.set(settings, {merge: true});

      const collectionRef2 = FirebaseAuth.firestore()
        .collection("subdomains")
        .doc(userData.currentAccount.id);

      // check if they are changing their domain, this is only needed for custom domains
      if(services.customSubdomain){
        const existingSubdomain = await collectionRef2.get();
        if(existingSubdomain.exists) {
          if(existingSubdomain.data().subdomainName !== subdomainName.value) {
            //Delete the old subdomain
            await deleteOldDomain();
          }
        }
      }

      await collectionRef2.set({
        subdomainName: subdomainName.value,
        zone: zoneName.value
      })
      // Toggle to get custom domain to start a new check
      setSettingsCommitted(!settingsCommitted);

      setSettingsCommitted(!validateForm());
      setDeployNeeded(true);
    } catch (err) {
      setSubdomainName({
        hasError: true,
        error: err.message,
        value: subdomainName.value
      });
      setErrorMessage(err.message);
    }
    setInSubmit(false);
    if(!validateForm()) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  const deleteOldDomain = async () => {
    const onCall = CloudFunctions.httpsCallable('oncall');
    await onCall({
      name: "deleteOldDomain",
      data: {
        accountId: userData.currentAccount.id,
      }
    })
  }

  const sendTestEmail = async () => {
    setInTestEmail(true);
    setEmailTestMessage(null);
    const testEmail = CloudFunctions.httpsCallable('oncall');
    testEmail({
      name: "sendTestApiErrorEmail",
      data: {
        accountId: userData.currentAccount.id,
        email: apiErrorEmail.value
      }
    }).then(res => {
      setEmailTestMessage("Test email sent.");
      setInTestEmail(false);
    }).catch(err => {
      setEmailTestMessage(err.message);
      setInTestEmail(false);
    });
  }

  const triggerDeploy = async () => {
    setInDeploy(true);
    setDeployErrorMessage(null);

    const deployAccount = CloudFunctions.httpsCallable('oncall');
    deployAccount({
      name: "deployAccount",
      data: {
        accountId: userData.currentAccount.id
      }
    }).then(res => {
      const settings = {
        lastDeploy: firebase.firestore.Timestamp.now()
      };
      const collectionRef = FirebaseAuth.firestore()
        .collection("accounts")
        .doc(userData.currentAccount.id)
        .collection("api_settings")
        .doc("private");
      collectionRef.set(settings, {merge: true})
      setInDeploy(false);
      setDeployNeeded(false);
      const DNSmessage = (services.customSubdomain && !subdomainName.value.includes(".ltiaas.com")) ? "" : "If this is your first time deploying, please be patient as it may take up to an hour for a DNS change to propagate."
      setDeploySuccessMessage(`Deploy Triggered Successfully. Your changes will be live at: https://${subdomainName.value} immediately. ${DNSmessage}`);
    }).catch(err => {
      setDeployErrorMessage(err.message);
      setInDeploy(false);
    });
  }

  const fetchSettings = async (accountId) => {
    let active_plans = {
      deepLinking: false,
      dynamicRegistration: false,
      customSubdomain: false,
      consumerService: false
    }
    let api_settings = null;
    let account = null;
    const collectionRef = FirebaseAuth.firestore()
      .collection("accounts")
      .doc(accountId)
    const account_doc = await collectionRef.get()
    if (account_doc.exists) {
      account = account_doc.data();
      setAccount(account);
      if(account.hasOwnProperty("type")) {
        setPlanType(account.type);
      }
      if(account.hasOwnProperty("firebase") && account.firebase === true) {
        setDeploymentMethod("firebase");
      }
      if (account.hasOwnProperty("plans")) {
        const planPromises = account.plans.map((plan) => {
          return plan.get();
        });
        const plans = await Promise.all(planPromises);
        
        plans.forEach((plan) => {
          if(plan.data().name.includes("Deep Linking")) {
            active_plans.deepLinking = true;
          }
          if(plan.data().name.includes("Dynamic Registration")) {
            active_plans.dynamicRegistration = true;
            active_plans.consumerService = true;
          }
          if(plan.data().name.includes("Grades")) {
            active_plans.consumerService = true;
          }
          if(plan.data().name.includes("Roles")) {
            active_plans.consumerService = true;
          }
          if(plan.data().name.includes("Custom Domain Name") || userData.currentAccount.features?.custom_domain) {
            active_plans.customSubdomain = true;
            setSubdomainName({
              hasError: false,
              error: null,
              value: "",
            });
          }
        });
        setServices(active_plans);
      }
    }

    const collectionRef1 = FirebaseAuth.firestore()
      .collection("accounts")
      .doc(accountId)
      .collection("api_settings")
      .doc("private");
    const doc = await collectionRef1.get();
    if (doc.exists) {
      api_settings = doc.data();
      if (api_settings.hasOwnProperty("toolName")) {
        setToolName({ value: api_settings.toolName });
      }
      if (api_settings.hasOwnProperty("toolDescription")) {
        setToolDescription({ value: api_settings.toolDescription });
      }
      if (api_settings.hasOwnProperty("launchURL")) {
        setLaunchURL({ value: api_settings.launchURL });
      }
      if (api_settings.hasOwnProperty("deepLinkingURL")) {
        setDeepLinkingURL({ value: api_settings.deepLinkingURL });
      }
      if (api_settings.hasOwnProperty("logoURL")) {
        setLogoURL({ value: api_settings.logoURL });
      }
      if (api_settings.hasOwnProperty("autoActivate")) {
        setAutoActivate({ value: api_settings.autoActivate });
      }
      if (api_settings.hasOwnProperty("serviceURL")) {
        setServiceURL({ value: api_settings.serviceURL });
      }
      if(api_settings.hasOwnProperty("apiErrorEmail")) {
        setApiErrorEmail({ value: api_settings.apiErrorEmail });
      }
      if (api_settings.hasOwnProperty("lastSave")) {
        if (api_settings.hasOwnProperty("lastDeploy")) {
          if(superAdmins.includes(authUser.user.uid)) {
            setDeployNeeded(true);
          } else if(api_settings.lastSave.toDate() > api_settings.lastDeploy.toDate()) {
            setDeployNeeded(true);
          } else {
            setDeployNeeded(false);
          }
        } else {
          setDeployNeeded(true);
        }
      }
    }
    
    const collectionRef3 = FirebaseAuth.firestore()
      .collection("subdomains")
      .doc(accountId);
    const doc3 = await collectionRef3.get();
    let has_subdomain = false;
    if (doc3.exists) {
      const subdomain = doc3.data();
      if (subdomain.hasOwnProperty("subdomainName")) {
        setSubdomainName({ value: subdomain.subdomainName, hasError: false, error: null });
        has_subdomain = true;
      }
      if (subdomain.hasOwnProperty("zone")) {
        setZoneName({ value: subdomain.zone });
      }
    }

    const collectionRef2 = FirebaseAuth.firestore()
      .collection("accounts")
      .doc(accountId);
    const doc2 = await collectionRef2.get();
    if (doc2.exists) {
      const account_settings = doc2.data();
      if (account_settings.hasOwnProperty("apiKey")) {
        setApiKey(account_settings.apiKey);
      } else {
        setApiKey("");
      }
      const extra_zones = [];
      if(account_settings.hasOwnProperty("availableZones")){
        account_settings.availableZones.forEach(z => {
          extra_zones.push(z.value)
        });
      } else if(userData.currentAccount.features?.dedicated_cpu) {
        setDedicatedCPUProvisioning(true);
      }
      const zones = FirebaseAuth.firestore().collection("zones")
      const zone_options = [];
      const querySnapshot = await zones.get();
      // put private zones at the top of the list
      querySnapshot.forEach((doc) => {
        if(extra_zones.includes(doc.id)){
          zone_options.push({
            value: doc.id,
            label: doc.data().name,
            prefix: doc.data().subdomainPrefix,
            disabled: (!(account.hasOwnProperty("firebase") && account.firebase === true)) && doc.id !== "us-central1"
          })
        }
      });
      // Add USA at the top of the list
      querySnapshot.forEach((doc) => {
        if(doc.data().public && doc.data().name.includes("US")){
          zone_options.push({
            value: doc.id,
            label: doc.data().name,
            prefix: doc.data().subdomainPrefix,
            disabled: (!(account.hasOwnProperty("firebase") && account.firebase === true)) && doc.id !== "us-central1"
          })
        }
      });
      // Add non-USA options
      querySnapshot.forEach((doc) => {
        if(doc.data().public && !doc.data().name.includes("US")){
          zone_options.push({
            value: doc.id,
            label: doc.data().name,
            prefix: doc.data().subdomainPrefix,
            disabled: (!(account.hasOwnProperty("firebase") && account.firebase === true)) && doc.id !== "us-central1"
          })
        }
      });
      if(has_subdomain){
        setSettingsCommitted(validateForm());
      }
      setZoneOptions(zone_options);
    } else {
      setApiKey("");
    }
    if(has_subdomain){
      setSettingsCommitted(validateForm());
    }
  };

  useEffect(() => {
    fetchSettings(userData.currentAccount.id);
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/account/" + userData.currentAccount.id + "/",
        text: userData.currentAccount.name + ` (${userData.currentAccount.id})`,
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb, title]);

  return (
    <>
      <div className="container-fluid">
        <div className="animated fadeIn">
            {dedicatedCPUProvisioning &&
            <div className="alert alert-warning" role="alert">
              <p>
                Your dedicated CPU and Database are currently being provisioned. This process can take up to 24 hours.
                {" "}Please check back here later. If you have any questions or concerns, please reach out to <a href="mailto:support@ltiaas.com">mailto:support@ltiaas.com</a>.
              </p>
            </div>
            }
            {apiKey !== null && settingsCommitted && deployNeeded && (
                <div className="card border-danger mb-3">
                    <div className="card-header text-danger">Deploy Needed</div>
                    <div className="card-body text-danger">
                        <p>
                        Your API settings have been saved, but they have not been deployed.
                        </p>
                        <button className={"btn mr-2 btn-danger"} onClick={triggerDeploy} disabled={(inDeploy?'disabled':'')}>
                        {inDeploy && <Loader /> }
                        {'Deploy Now'}
                        </button>
                    </div>
                </div>
            )}
            
            <CustomDomain
              enabled={settingsCommitted && services.customSubdomain && deploymentMethod === "firebase" && subdomainName.value.length > 0 && !subdomainName.value.endsWith(".ltiaas.com")}
              subdomainName={subdomainName.value}
              settingsCommitted={settingsCommitted}
              account={account}
            ></CustomDomain> 
            <ApiKey
              settingsCommitted={settingsCommitted}
              deploymentMethod={deploymentMethod}
              accountApiKey={apiKey}
            ></ApiKey>
            <ConsumerPublicKey
              enabled={settingsCommitted && deploymentMethod === "firebase" && planType === "consumer"}
            ></ConsumerPublicKey>
            <OAuthSecrets
              enabled={settingsCommitted && deploymentMethod === "firebase" && planType !== "consumer" && userData.currentAccount.features?.legacy_lti_support}
            ></OAuthSecrets>
            {deployErrorMessage !== null && (
              <Alert
                  type="danger"
                  message={deployErrorMessage}
                  dismissible={true}
                  onDismiss={() => setDeployErrorMessage(null)}
              ></Alert>
            )}
            {deploySuccessMessage !== null && (
              <Alert
                  type="success"
                  message={deploySuccessMessage}
                  dismissible={true}
                  onDismiss={() => setDeploySuccessMessage(null)}
              ></Alert>
            )}
          {!settingsCommitted && apiKey != null && (
            <div className="card">
              <div className="card-header">Activate Your API</div>
              <div className="card-body">
                <p>
                  Please fill out the settings below so that your custom API
                  subdomain can be activated and your. Once the from is
                  complete, we will provide you with your API key.
                </p>
              </div>
            </div>
          )}
          <div className="card">
            <div className="card-header">{title}</div> 
            <div className="card-body">
              <Form
                handleSubmit={(e) => {
                  e.preventDefault();
                  setInSubmit(true);
                  setErrorMessage(null);
                  updateAPISettings().catch((err) => {
                    setErrorMessage(err.message);
                    setInSubmit(false);
                  });
                }}
                disabled={validateForm()}
                inSubmit={inSubmit}
                enableDefaultButtons={true}
              >
                {deploymentMethod === "firebase" && 
                  <Field
                    label="Data Region"
                    description="Choose the region in which you want your data hosted in. NOTE: After this is provisioned, it cannot be changed. If you want to change your data region after provisioning, please contact support@ltiaas.com."
                  >
                    <Select
                      value={zoneName.value}
                      name="zone-name"
                      required={true}
                      disabled={settingsCommitted}
                      error={zoneName.error}
                      hasError={zoneName.hasError}
                      options={zoneOptions}
                      changeHandler={changeZoneName}
                    />
                  </Field>
                }
                <SubdomainField
                  services={services}
                  settingsCommitted={settingsCommitted}
                  subdomainName={subdomainName}
                  setSubdomainName={setSubdomainName}
                  zoneName={zoneName}
                  zoneOptions={zoneOptions}
                ></SubdomainField>
                <Field
                  label={planType === "consumer" ? "Name" : "Tool Name"}
                  description={planType === "consumer" ? "This is the name that is saved into the tool's database." : "This is the title that is displayed in the LMS after the tool is linked."}
                >
                  <Input
                    type="text"
                    value={toolName.value}
                    name="tool-name"
                    maxLen={100}
                    required={true}
                    changeHandler={setToolName}
                  />
                </Field>
                {planType === "provider" &&
                  <Field
                    label="Tool Description"
                    description="This is the short description that is displayed in the LMS after the tool is linked. Just a short sentence is best."
                  >
                    <Input
                      type="text"
                      value={toolDescription.value}
                      name="tool-description"
                      maxLen={100}
                      required={true}
                      changeHandler={setToolDescription}
                    />
                  </Field>
                }
                <Field
                  label="Launch URL"
                  description={planType === "consumer" ? "This is the URL (on your server) that is provisioned to receive LTI launch requests from tools (ex. https://example.com/lti-launch). It will receive a POST request with a JWT that needs to be validated with your public key. You will then take that information and send it to the LTIAAS consumer idtoken endpoint." : "This is the URL (on your server) that is provisioned to receive LTI launch commands. (ex. https://example.com/lti-launch). It will receive a GET request with a query parameter that you can use to authenticate the connection bu querying our API."}
                >
                  <Input
                    type="text"
                    value={launchURL.value}
                    name="launch-url"
                    maxLen={500}
                    required={true}
                    validRegex={"^https?:\\/\\/[a-zA-Z0-9\\.\\-\\_]+.+"}
                    customErrorMessage={"Must be a valid URL starting with http(s)://"}
                    changeHandler={setLaunchURL}
                  />
                </Field>
                {planType === "consumer" && services.consumerService &&
                <Field
                  label="Service URL"
                  description="This is the URL (on your server) that LTIAAS redirects to after receiving service requests. (ex. https://example.com/lti-service)."
                >
                  <Input
                    type="text"
                    value={serviceURL.value}
                    name="service-url"
                    maxLen={500}
                    required={true}
                    validRegex={"^https?:\\/\\/[a-zA-Z0-9\\.\\-\\_]+.+"}
                    customErrorMessage={"Must be a valid URL starting with http(s)://"}
                    changeHandler={setServiceURL}
                  />
                </Field>
                }
                {services.deepLinking && 
                <>
                  <div className="card-header rounded-top bg-light mb-3">Deep Linking Settings</div>
                  <Field
                    label="Deep Linking URL"
                    description="For LTI Deep Linking, this is the URL (on your server) that is provisioned to receive LTI deep-linking commands. This will be the page that is displayed to the teacher that allows them to select/configure an activity. (ex. https://example.com/lti-deep-linking). It will receive a GET request with a query parameter that you can use to authenticate the connection bu querying our API."
                  >
                    <Input
                      type="text"
                      value={deepLinkingURL.value}
                      name="deep-linking-url"
                      maxLen={500}
                      required={false}
                      validRegex={"^https?:\\/\\/[a-zA-Z0-9\\.\\-\\_]+.+"}
                      customErrorMessage={"Must be a valid URL starting with http(s)://"}
                      changeHandler={setDeepLinkingURL}
                    />
                  </Field>
                </>
                }
                {services.dynamicRegistration && 
                <div className="card-header rounded-top bg-light mb-3">Dynamic Registration Settings</div>
                }
                {planType === "provider" && 
                <Field
                  label="Logo URL"
                  logo={logoURL.value}
                  description={services.dynamicRegistration ? "The URL to your tool's logo that will be shown in the LMS. Recommended size: 128x128px. This should be a URL to an image hosted by you." : "The URL to your tool's logo that will be shown in the unlikely event that an error message needs to be displayed to the user."}
                >
                  <Input
                    type="text"
                    value={logoURL.value}
                    name="logo-url"
                    maxLen={100}
                    required={false}
                    validRegex={"^https?:\\/\\/[a-zA-Z0-9\\.\\-\\_]+.+"}
                    customErrorMessage={"Must be a valid URL starting with http(s)://"}
                    changeHandler={setLogoURL}
                  />
                </Field>
                }
                {services.dynamicRegistration && 
                <Field
                  label="Dynamic Registration Auto-Activation"
                  description="Enabling this will activate all registration done through the dynamic registration API. If you want to approve every activation request first (i.e. after the customer pays me), then leave this disabled."
                >
                  <div className="toggle">
                    <Input
                      type="radio"
                      name="auto-activate"
                      value="true"
                      required={true}
                      changeHandler={setAutoActivate}
                      id="autoActivateTrue"
                      checked={autoActivate.value === "true"}
                    />
                    <label htmlFor="autoActivateTrue">Enabled</label>
                    <Input
                      type="radio"
                      name="auto-activate"
                      value="false"
                      required={true}
                      changeHandler={setAutoActivate}
                      id="autoActivateFalse"
                      checked={autoActivate.value !== "true"}
                    />
                    <label htmlFor="autoActivateFalse">Disabled</label>
                  </div>
                </Field>
                }
                {userData.currentAccount.features?.error_notifications && 
                <>
                  <div className="card-header rounded-top bg-light mb-3">Notifications On API Errors</div>
                  <Field
                    label="Email Address"
                    description="LTIAAS can send you an email when your API generates an unexpected error. Enter an email address below and our API monitoring service will notify you with information that you can use to take action in the event of an error generated by our API."
                    extra={emailTestMessage}
                  >
                    <Input
                      type="text"
                      value={apiErrorEmail.value}
                      name="error_notification-email"
                      maxLen={500}
                      required={false}
                      validRegex={"[a-z0-9]+@[a-z]+\.[a-z]{2,3}"}
                      customErrorMessage={"Must be a valid email address"}
                      changeHandler={setApiErrorEmail}
                    />
                    <button className="btn btn-primary mt-1" style={{float: "right"}} disabled={inTestEmail||apiErrorEmail.hasError||apiErrorEmail.value===""} onClick={sendTestEmail}>
                      {inTestEmail && <Loader /> } Send Test Email
                    </button>
                  </Field>
                </>
                }
                {errorMessage !== null && (
                    <Alert
                        type="danger"
                        message={errorMessage}
                        dismissible={true}
                        onDismiss={() => setErrorMessage(null)}
                    ></Alert>
                )}
              </Form>
            </div> 
          </div>
        </div>
      </div>
    </>
  );
};

export default APISettings;
