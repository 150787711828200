import React, {useState, useContext, useEffect} from "react";
import { BreadcrumbContext } from '../../../components/Breadcrumb';
import { FirebaseAuth } from "../../../components/FirebaseAuth/firebase";
import { Link, Navigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { AuthContext } from "../../../components/FirebaseAuth";
import { Button, Form, InputGroup, Table, Tooltip } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Input } from "../../../components/Form";
import AlertPreformatted from "../../../components/AlertPreformatted";
import { CloudFunctions } from "../../../components/FirebaseAuth/firebase";
import { superAdmins } from "../superAdmins"
import Plot from 'react-plotly.js';
import Placeholder from 'react-bootstrap/Placeholder';

const Home = () => {
    const title = 'My Accounts';

    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const { authUser } = useContext(AuthContext);
    
    const [searchName, setSearchName] = useState({
        hasError: false,
        error: null,
        value: "",
    });
    const [searchId, setSearchId] = useState({
        hasError: false,
        error: null,
        value: "",
    });
    

    const [sort, setSort] = useState(false);
    const [sortFirebase, setSortFirebase] = useState(false);
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [gettingStats, setGettingStats] = useState(false);
    const [stats, setStats] = useState({});
    const [showRenameForm, setShowRenameForm] = useState(false);
    const [savingAccountName, setSavingAccountName] = useState(false);
    const [selectedAccountName, setSelectedAccountName] = useState("");
    const [selectedAccountId, setSelectedAccountId] = useState("");

    const sortedAccounts = accounts.sort((a, b) => {
        const statusA = a.subscriptionStatus !== undefined;
        const statusB = b.subscriptionStatus !== undefined;
        if (statusA && !statusB) {
          return sort ? 1 : -1;
        }
        if (!statusA && statusB) {
          return sort ? -1 : 1;
        }
        return 0;
    });

    const firebaseSortedAccounts = sortedAccounts.sort((a, b) => {
        const statusA = a.firebase;
        const statusB = b.firebase;
        if (statusA && !statusB) {
          return sortFirebase ? -1 : 1;
        }
        if (!statusA && statusB) {
          return sortFirebase ? 1 : -1;
        }
        return 0;
    });

    const filteredAccounts = firebaseSortedAccounts.filter((item) => {
        if(searchName.value.length > 1) {
            return item.name.toLowerCase().includes(searchName.value.toLowerCase())
        } else if(searchId.value.length > 1) {
            return item.id.toLowerCase().includes(searchId.value.toLowerCase())
        } else {
            return true
        }
    });
    const [closedAccounts, setClosedAccounts] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    const getStats = async (id, subscriptionCurrentPeriodEnd) => {
        setGettingStats(true);
        const onCall = CloudFunctions.httpsCallable('oncall');
        try{
            const mets = await onCall({name: "getApiMetrics", data: {accountId: id} });
            const regs = await onCall({ name: "getRegistrations", data: {accountId: id} })
            const dateEnd = new Date(0); // The 0 there is the key, which sets the date to the epoch
            dateEnd.setUTCSeconds(subscriptionCurrentPeriodEnd);
            const dateToday = new Date();
            const DifferenceInTime = dateEnd.getTime() - dateToday.getTime();
            const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
            const data = {BillingEndsIn: parseFloat(DifferenceInDays).toFixed(2) + " days", Registrations: regs.data.hasOwnProperty('platforms') ? regs.data.platforms.length : regs.data.length, Metrics: mets.data[0]};
            setErrorMessage(JSON.stringify(data, null, 2));
            setGettingStats(false);
        } catch (e) {
            setErrorMessage("ERROR: " + e);
            setGettingStats(false);
        }
    }

    const getDomain = async (id) => {
        const subdomainRef = await FirebaseAuth.firestore().collection('subdomains').doc(id).get();
        if (!subdomainRef) return null
        const subdomain = subdomainRef.data();
        if(subdomain !== undefined) {
            const sorted = Object.keys(subdomain)
            .sort()
            .reduce((accumulator, key) => {
              accumulator[key] = subdomain[key];
              return accumulator;
            }, {});

            setErrorMessage(JSON.stringify(sorted, null, 2));
        } else {
            const accountRef = await FirebaseAuth.firestore().collection('accounts').doc(id).get();
            if (!accountRef) {
                setErrorMessage("This account has not been deployed yet")
            } else {
                const account = accountRef.data();
                let found = false;
                for(let i=0; i<account.plans.length; i++) {
                    const plan = await account.plans[i].get();
                    if(plan.data().name.includes("Custom Domain")) {
                        found = true;
                    }
                }
                if(found) {
                    setErrorMessage("WARNING: This account as a Custom Domain, but has not been deployed yet")
                } else {
                    setErrorMessage("This account has not been deployed yet")
                }
            }
        }
    }

    const switchToFirebase = async (id) => {
        const subdomainRef = await FirebaseAuth.firestore().collection('subdomains').doc(id).get();
        if (!subdomainRef) return null
        const subdomain = subdomainRef.data();
        if(subdomain !== undefined) {
            setErrorMessage("This account has already been activated, it needs to be migrated manually.")
        } else {
            const accountRef = FirebaseAuth.firestore().collection("accounts").doc(id)
            const accountRefGet = await accountRef.get()
            const account = accountRefGet.data();
            let found = false;
            if(account.plans) {
                for(let i=0; i<account.plans.length; i++) {
                    const plan = await account.plans[i].get();
                    if(plan.data().name.includes("Custom Domain")) {
                        found = true;
                    }
                }
            }
            if(found) {
                setErrorMessage("WARNING: This account as a Custom Domain, but has not been deployed yet.\nIt was NOT moved to firebase.")
            } else {
                await accountRef.set({
                    firebase: true,
                    apiKey: ""
                }, {merge: true});
                setErrorMessage("Account is now on firebase")
            }
        }
        
    }

    const saveAccountName = async () => {
        setSavingAccountName(true);
        const changeAccountName = CloudFunctions.httpsCallable('oncall');
        await changeAccountName({
            name: "changeAccountName",
            data: {
                accountId: selectedAccountId,
                name: selectedAccountName
            }
        })
        await getAccounts();
        setSavingAccountName(false);
        setShowRenameForm(false);
    }
    
    const getAccounts = async () => {
        setLoading(true);
        let records = [];
        let closedRecords = [];
        const accountsRef = FirebaseAuth.firestore().collection('accounts');
        let query = accountsRef.where('access', 'array-contains', FirebaseAuth.auth().currentUser.uid);
        const accountSnapshots = await query.get();
        accountSnapshots.forEach(account => {
            records.push({
                'id': account.id,
                'name': account.data().name,
                'subscriptionStatus': account.data().subscriptionStatus,
                'firebase': account.data().firebase,
                'features': account.data().features,
                'stripeActiveSubscriptionID': account.data().stripeActiveSubscriptionID,
                'subscriptionCurrentPeriodEnd': account.data().subscriptionCurrentPeriodEnd,
                'type': account.data().type
            });
        });
        setAccounts(records);
        let closedQuery = accountsRef
            .where("accessCount", "==", 0)
            .where('owner', '==', FirebaseAuth.auth().currentUser.uid);
        const closedAccountSnapshots = await closedQuery.get();
        closedAccountSnapshots.forEach(account => {
            closedRecords.push({
                'id': account.id,
                'name': account.data().name,
                'subscriptionCurrentPeriodEnd': account.data().subscriptionCurrentPeriodEnd
            });
        });
        setClosedAccounts(closedRecords);
        setLoading(false);
    }

    useEffect(() => {
        const getAllAccounts = async () => {
            if(superAdmins.includes(FirebaseAuth.auth().currentUser.uid)) {
                const accountsRef = FirebaseAuth.firestore().collection('accounts');
                const times = [];
                const accts = [];
                const inactives = [];
                const frees = [];
                const actives = [];
                const paids = [];
                const allAccountSnapshots = await accountsRef.orderBy('creationTime', 'asc').get();
                let totalAccounts = 0;
                let totalActiveAccounts = 0;
                let totalInactiveAccounts = 0;
                let totalFreeAccounts = 0;
                allAccountSnapshots.forEach(account => {
                    const myDate = account.data().creationTime.toDate();
                    var noTime = new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate());
                    times.push(noTime.getTime());
                    accts.push(++totalAccounts);
                    if(account.data().subscriptionStatus === "active") {
                        actives.push(++totalActiveAccounts);
                    } else {
                        actives.push(totalActiveAccounts);
                    }
                    if(account.data().subscriptionStatus !== "active") {
                        inactives.push(++totalInactiveAccounts);
                    } else {
                        inactives.push(totalInactiveAccounts);
                    }
                    if(account.data().subscriptionStatus === "active" && account.data().features?.trial === true) {
                        frees.push(++totalFreeAccounts);
                    } else {
                        frees.push(totalFreeAccounts);
                    }
                    paids.push(totalActiveAccounts - totalFreeAccounts);
                });
                
                setStats({
                    times: times,
                    accts: accts,
                    actives: actives,
                    inactives: inactives,
                    frees: frees,
                    paids: paids
                })
            }
        }

        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
        getAccounts();
        getAllAccounts();
    },[setBreadcrumb]);

    const rename_tooltip = (
        <Tooltip id="tooltip">
            Rename Account
        </Tooltip>
    );

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    {!loading &&(
                        <div className="text-right mb-3">
                            <Link to="/new-account" className="btn btn-primary"><i className="fa fa-plus"></i> Add Account</Link>
                        </div>
                    )}
                    {superAdmins.includes(authUser.user.uid) && (
                        <>
                            {stats.times ? 
                                <Plot
                                    data={[
                                        {
                                            x: stats.times,
                                            y: stats.accts,
                                            type: 'scatter',
                                            mode: 'lines',
                                            marker: {color: '#023E73'},
                                            name: 'All Accounts'
                                        },
                                        {
                                            x: stats.times,
                                            y: stats.actives,
                                            type: 'scatter',
                                            mode: 'lines',
                                            marker: {color: '#3FBF04'},
                                            name: 'Active Accounts'
                                        },
                                        {
                                            x: stats.times,
                                            y: stats.frees,
                                            type: 'scatter',
                                            mode: 'lines',
                                            marker: {color: '#BF9604'},
                                            name: 'Free Accounts'
                                        },
                                        {
                                            x: stats.times,
                                            y: stats.paids,
                                            type: 'scatter',
                                            mode: 'lines',
                                            marker: {color: '#7E04BF'},
                                            name: 'Paid Accounts'
                                        },
                                        {
                                            x: stats.times,
                                            y: stats.inactives,
                                            type: 'scatter',
                                            mode: 'lines',
                                            marker: {color: '#BF0436'},
                                            name: 'Inactive Accounts'
                                        },
                                    ]}
                                    showlegend={true}
                                    legend={{
                                        borderwidth: 0,
                                        x: 1,
                                        xanchor: 'right',
                                        y:1.1,
                                        orientation: 'h' 
                                    }}
                                    layout={{
                                        title: 'Daily Account Creations',
                                        images: [{
                                            name: 'watermark_1',
                                            source: "/logo_large.png",
                                            xref: "paper",
                                            yref: "paper",
                                            x: 0.05,
                                            y: 0.9,
                                            sizex: 0.3,
                                            sizey: 0.3,
                                            opacity: 0.1,
                                            layer: "below"
                                        }],
                                        xaxis: {
                                            autorange: true,
                                            rangeselector: {buttons: [
                                                {
                                                    count: 1,
                                                    label: '1d',
                                                    step: 'day',
                                                    stepmode: 'backward'
                                                },
                                                {
                                                    count: 7,
                                                    label: '7d',
                                                    step: 'day',
                                                    stepmode: 'backward'
                                                },
                                                {
                                                    count: 1,
                                                    label: '1m',
                                                    step: 'month',
                                                    stepmode: 'backward'
                                                },
                                                {
                                                    count: 1,
                                                    label: '1y',
                                                    step: 'year',
                                                    stepmode: 'backward'
                                                },
                                                {step: 'all'}
                                            ]},
                                            rangeslider: {autorange: true},
                                            type: 'date'
                                        },
                                        yaxis: {
                                            autorange: true,
                                            type: 'linear'
                                        },
                                        margin: {
                                            b:10,
                                            l:60,
                                            r:10,
                                            t:60
                                        }
                                    }}
                                    useResizeHandler={true}
                                    style={{width: "100%", height: "450px"}}
                                ></Plot>
                                :
                                <Placeholder animation="glow" style={{ height: 100, width: "100%" }} />
                            }  
                            <div className="card">
                                <div className="card-header font-weight-bold">
                                    <h5>Your Accounts</h5>
                                </div>
                                {errorMessage !== null && (
                                    <AlertPreformatted
                                        type="warning"
                                        message={errorMessage}
                                        dismissible={true}
                                        onDismiss={() => setErrorMessage(null)}
                                    ></AlertPreformatted>
                                )}
                                <div className="card-body">
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                            <th>Account Name</th>
                                            <th>Account Id</th>
                                            <th>Stripe Id</th>
                                            <th>Firebase</th>
                                            <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Input
                                                        type="text"
                                                        value={searchName.value}
                                                        placeholder="Search..."
                                                        name="searchName"
                                                        required={false}
                                                        changeHandler={setSearchName}>
                                                    </Input>
                                                </td>
                                                <td>
                                                    <Input
                                                        type="text"
                                                        value={searchId.value}
                                                        placeholder="Search..."
                                                        name="searchId"
                                                        required={false}
                                                        changeHandler={setSearchId}>
                                                    </Input>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <Button onClick={() => setSortFirebase(!sortFirebase)}>Sort</Button>
                                                </td>
                                                <td>
                                                    <Button onClick={() => setSort(!sort)}>Sort</Button>
                                                </td>
                                            </tr>
                                            {filteredAccounts.map((account, i) => 
                                                <tr key={account.id}>
                                                <td>{account.name}{account.type === "consumer" && (<i> (consumer)</i>)}{account.features?.trial && (<i> (trial)</i>)}</td>
                                                <td>{account.id}</td>
                                                <td>{account.stripeActiveSubscriptionID}</td>
                                                <td>{account.firebase ? "true" : "false"}</td>
                                                <td>
                                                    {account.subscriptionStatus?(
                                                        <>
                                                            <Button href={'/account/'+account.id+'/'} variant="primary">Account Overview</Button>
                                                            <Button variant="info" onClick={() => getDomain(account.id)}>Domain?</Button>
                                                            <Button variant="success" disabled={gettingStats} onClick={() => getStats(account.id, account.subscriptionCurrentPeriodEnd)}>Stats?</Button>
                                                        </>
                                                    ):(
                                                        <>
                                                            <Button href={'/account/'+account.id+'/billing/plan'} variant="warning">Activate the account</Button>
                                                        </>
                                                    )}
                                                    {false && !account.firebase &&
                                                        <Button variant="danger" onClick={() => switchToFirebase(account.id)}>Switch To Firebase</Button>
                                                    }
                                                </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </>
                    )}

                    {!superAdmins.includes(authUser.user.uid) ? (
                        <>
                            {accounts.map((account, i) => 
                                
                                <div className="card" key={account.id}>
                                    <div className="card-header font-weight-bold">
                                        <h6 style={{float: "right", color:"#aaaaaa"}}>Account ID: <i>{account.id}</i></h6>
                                        <h5>
                                            {(showRenameForm && selectedAccountId === account.id) ? 
                                            <>
                                                <InputGroup>
                                                    <Form.Control
                                                        type="text"
                                                        disabled={savingAccountName}
                                                        value={selectedAccountName} onChange={(e) => setSelectedAccountName(e.target.value)}
                                                    />

                                                    {savingAccountName ? (
                                                        <Button variant="success" disabled>
                                                            <i className="fa fa-spinner fa-spin"></i>
                                                        </Button>
                                                    ) : (
                                                        <>
                                                            <Button variant="success" onClick={() => {saveAccountName()}}>
                                                                <i className="fa fa-save"></i>
                                                            </Button>
                                                            <Button variant="secondary" onClick={() => {setShowRenameForm(false)}}>
                                                                <i className="fa fa-times"></i>
                                                            </Button>
                                                        </>
                                                    )}
                                                        
                                                </InputGroup>
                                            </>
                                            :
                                            <>
                                                {account.name}{" "}
                                                <OverlayTrigger placement="top" overlay={rename_tooltip}>
                                                    <Button variant="light" onClick={() => {setSelectedAccountId(account.id); setSelectedAccountName(account.name); setShowRenameForm(true)}}>
                                                        <i className="fa fa-edit"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                            </>
                                            }
                                            
                                        </h5>
                                        {account.type === "consumer" && <h6>(consumer)</h6>}
                                    </div>
                                    <div className="card-body">
                                        {account.subscriptionStatus?(
                                            <>
                                                {account.features?.trial &&
                                                    <Button style={{float:"right"}} href={'/account/'+account.id+'/billing/plan'} variant="success">
                                                        <i className="fas fa-arrow-circle-up"></i> Upgrade
                                                    </Button>
                                                }
                                                <Button href={'/account/'+account.id+'/'} variant="primary">
                                                    <i className="fa fa-tachometer-alt mr-1"></i> Account Overview
                                                </Button>
                                            </>
                                        ):(
                                            <>
                                                <Button style={{float:"right"}} href={'/account/'+account.id+'/billing/delete'} variant="danger">
                                                    <i className="fas fa-trash"></i> Delete
                                                </Button>
                                                <Button href={'/account/'+account.id+'/billing/plan'} variant="warning">
                                                    <i className="fas fa-check"></i> Activate the account
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                
                            )}
                            
                        </>
                    ) : accounts.length === 0 && (
                        <>
                            {(loading) ? (
                                <Loader text="loading accounts..."></Loader>
                            ):(
                                <Navigate to="/new-account"></Navigate>
                            )}
                        </>
                    )}
                    {closedAccounts.length > 0 && (
                        <>
                            {closedAccounts.map((account, i) => 
                                
                                <div className="card" key={account.id}>
                                    <div className="card-header font-weight-bold">
                                        <h5>{account.name}</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="alert alert-warning" role="alert">
                                            This account has been closed.
                                        </div>
                                        <Button href={'/account/'+account.id+'/billing'} variant="primary">
                                            <i className="fa fa-file-invoice-dollar"></i> View Invoices
                                        </Button>
                                    </div>
                                </div>
                                
                            )}
                        </>
                    )}
                </div>
            </div>
        </>

    )
}

export default Home;