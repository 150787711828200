import React, {useState, useContext, useEffect } from "react";
import { FirebaseAuth, CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import Loader from '../../../../components/Loader';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Alert from "../../../../components/Alert";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
const countries = require("../../../../inc/country.json");

const Plans = () => {

    const title = 'Create Your Plan';
    const { userData, authUser } = useContext(AuthContext);
    const stripe = useStripe();
    const elements = useElements();
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const planDiv = React.createRef();

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
              color: '#32325d',
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': {
                color: '#aab7c4'
              }
            },
            invalid: {
              color: '#fa755a',
              iconColor: '#fa755a'
            }
        }
    };

    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [tier, setTier] = useState(null);
    const [tierClicked, setTierClicked] = useState(false);
    const [freeTier, setFreeTier] = useState(false);
    const [tiers, setTiers] = useState([]);
    const [plans, setPlans] = useState([]);
    const [selectedPlans, setSelectedPlans] = useState([]);
    const [cardError, setCardError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [country, setCountry] = useState("");
    const [countryError, setCountryError] = useState(null);
    const [state, setState] = useState("");
    const [stateError, setStateError] = useState(null);
    const [selectedPlansError, setSelectedPlansError] = useState(null);

    const toggleSelectedPlanClick = (id) => {
        const index = selectedPlans.indexOf(id);
        if (index > -1) {
            //remove existing item from array
            setSelectedPlans(selectedPlans.filter(item => item !== id))
        } else {
            // add item to array as it was not found
            setSelectedPlans(prevArray => [...prevArray, id])
        }
    };

    useEffect(() => {
        if(tier != null && planDiv.current && tierClicked){
            planDiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }, [tierClicked])

    // Can be used in the future to set errors when certain plan options must be set in unison.
    /*useEffect(() => {
        if(selectedPlans.indexOf("moodle_usd") > -1) {
            if(selectedPlans.indexOf("deeplinking_usd") > -1) {
                setSelectedPlansError(null);
            } else {
                setSelectedPlansError("When the 'Moodle Plugin' plan option is chosen, the 'Deep Linking' plan option is required.");
            }
        } else {
            setSelectedPlansError(null);
        }
    }, [selectedPlans]);*/

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/"+userData.currentAccount.id+"/",
                text: userData.currentAccount.name + ` (${userData.currentAccount.id})`,
                active: false
            },      
            {
                to: null,
                text: title,
                active: true
            }
        ]);

        var sidebarNode = document.querySelector('#sidebar');
        sidebarNode.classList.add('c-sidebar-minimized');
        getTierData(userData);
        getPlanData(userData);
    },[userData, setBreadcrumb, title]);

    const getTierData = async(userData) => {
        const tierSnapShots = await FirebaseAuth.firestore().collection('tiers').where("currency", "==", "usd").get();
        let t = [];
        tierSnapShots.forEach(doc => {
            t.push({
                'id': doc.id,
                'name': doc.data().name,
                'price': doc.data().price,
                'available': doc.data().available,
                'currency': doc.data().currency,
                'priceUnits': doc.data().priceUnits,
                'mau': doc.data().mau,
                'features': doc.data().features,
                'planId': doc.data().planId,
                'current': (userData.currentAccount.tierId===doc.id)?true:false
            });
        });
        if(t.length > 0){
            const docs = await FirebaseAuth.firestore().collection("accounts").doc(userData.currentAccount.id).get();
            if (docs.exists) {
                const account = docs.data();
                if(account.tier){
                    const tier = await account.tier.get();
                    setTier(tier.id);
                    if(tier.data().name.includes("Free")) {
                        setFreeTier(true);
                    }
                }
            }
            const ascendingOrderTiers = t.sort((a, b) => a.price - b.price);
            setTiers(ascendingOrderTiers);
        }
    }

    const getPlanData = async(userData) => {
        const planSnapShots = await FirebaseAuth.firestore().collection('plans').where("currency", "==", "usd").where("available", "==", true).get();
        let p = [];
        planSnapShots.forEach(doc => {
            p.push({
                'id': doc.id,
                'order': doc.data().id,
                'required': doc.data().required,
                'description': doc.data().description,
                'name': doc.data().name,
                'price': doc.data().price,
                'currency': doc.data().currency,
                'paymentCycle': doc.data().paymentCycle,
                'features': doc.data().features,
                'stripePriceId': doc.data().stripePriceId,
                'current': (userData.currentAccount.planId===doc.id)?true:false
            });
        });
        if(p.length > 0){
            const docs = await FirebaseAuth.firestore().collection("accounts").doc(userData.currentAccount.id).get();
            if (docs.exists) {
                const account = docs.data();
                if(account.plans){
                    const planPromises = account.plans.map((plan) => {
                        return plan.get();
                    });
                    const plans = await Promise.all(planPromises);
                    plans.forEach((plan) => {
                        p.forEach((pi) => {
                            if (pi.name === plan.data().name) {
                                toggleSelectedPlanClick(pi.id);
                            }
                        });
                    });
                } else {
                    p.forEach((pi) => {
                        if (pi.required) {
                            toggleSelectedPlanClick(pi.id);
                        }
                    });
                }
            } else {
                p.forEach((pi) => {
                    if (pi.required) {
                        toggleSelectedPlanClick(pi.id);
                    }
                });
            }
            const ascendingOrderPlans = p.sort((a, b) => a.order - b.order);
            setPlans(ascendingOrderPlans);
        }
        setLoading(false);
    }

    const subcribe = async(event) => {
        event.preventDefault();
        setProcessing(true);
        setErrorMessage(null);

        let hasError = false;
        let paymentMethodId = '';

        if(country === ''){
            setCountryError('Please select a country.');
            hasError = true;
        }

        if(state === '' && countries[country] && countries[country].states){
            setStateError('Please select a state.');
            hasError = true;
        }

        setCardError(null);

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
        });

        if (error) {
            setCardError(error.message);
            hasError = true;
        } else {
            paymentMethodId = paymentMethod.id;
        }

        
        if(!hasError){
            const thisTier = tiers.filter(item => item.id === tier);
            let allPlans = selectedPlans;
            if(thisTier.length === 1 && thisTier[0].planId) {
                allPlans = [...selectedPlans, thisTier[0].planId]
            }

            const createSubscription = CloudFunctions.httpsCallable('oncall');
            createSubscription({
                name: "createSubscription",
                data: {
                    plans: allPlans,
                    tier: tier,
                    accountId: userData.currentAccount.id,
                    paymentMethodId: paymentMethodId,
                    billing: {
                        country: country,
                        state: state
                    }
                }
            }).then(res => {
                // physical page load to reload the account data
                document.location = '/account/'+userData.currentAccount.id+'/settings';
            }).catch(err => {
                setProcessing(false);
                setErrorMessage(err.message);
            });
        }else{
            setProcessing(false);
        }
    }

    const subcribe_free = async(event) => {
        event.preventDefault();
        setProcessing(true);
        setErrorMessage(null);

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        
        const thisTier = tiers.filter(item => item.id === tier);
        let allPlans = selectedPlans;
        if(thisTier.length === 1 && thisTier[0].planId) {
            allPlans = [...selectedPlans, thisTier[0].planId]
        }

        const createSubscription = CloudFunctions.httpsCallable('oncall');
        createSubscription({
            name: "createSubscription",
            data: {
                plans: allPlans,
                tier: tier,
                accountId: userData.currentAccount.id,
                paymentMethodId: null,
                billing: {
                    country: '',
                    state: ''
                }
            }
        }).then(res => {
            // physical page load to reload the account data
            document.location = '/account/'+userData.currentAccount.id+'/settings';
        }).catch(err => {
            setProcessing(false);
            setErrorMessage(err.message);
        });
}

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="row">
                        <div className="col-12">
                            {(userData.currentAccount.owner === authUser.user.uid)?(
                                <>
                                    <div className="row row-responsive">
                                        <div className="col-12 column-responsive">
                                            {tiers.length > 0 && (
                                                <>
                                                    <div className="text-center"><h3>Choose Your Tier</h3></div>
                                                    <div className="row justify-content-md-center equal">
                                                        <div className="col col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                            <div className="card-columns-5 mb-6 text-center">
                                                                {tiers.map((plan,i) =>
                                                                    <>
                                                                        <div className={(tier === plan.id) ? "card border-success" : (plan.name.includes("Self Hosted") ? "card bg-light" : "card")} key={i+plan.id}>
                                                                            <div className="card-header">
                                                                                <h2 className="mt-0 font-weight-normal">
                                                                                    {plan.name}
                                                                                </h2>
                                                                                <h5 className="card-title">
                                                                                    <div >
                                                                                        ${plan.price}
                                                                                        <small className="text-muted">
                                                                                            /{plan.priceUnits}
                                                                                        </small>
                                                                                    </div>
                                                                                    <div>
                                                                                        ${plan.mau}
                                                                                        <small className="text-muted">
                                                                                            /MAU
                                                                                            {" "}<OverlayTrigger
                                                                                                placement="bottom"
                                                                                                overlay={
                                                                                                    <Tooltip id={`tooltip-MAU-${plan.id}`}>
                                                                                                    MAU = Monthly Active User. For example, if you have one user that uses your LTI tool every day, several times a day this month, we only bill you for one Monthly Active User (MAU). If you have 100 users, each using your tool only once this month, we will bill you for 100 MAU.
                                                                                                    </Tooltip>
                                                                                                }
                                                                                                >
                                                                                                <i className="far fa-question-circle"></i>
                                                                                                </OverlayTrigger>
                                                                                        </small>
                                                                                    </div>
                                                                                </h5>
                                                                            </div>
                                                                            <div className="card-body text-left">
                                                                                <ul className="list-unstyled mt-2 mb-0">
                                                                                    {plan.features.sort((a,b) => a.order - b.order).map((feature, j) => 
                                                                                        <>
                                                                                            <li key={j+plan.id}>
                                                                                                <i className={feature.available === 1 ? "fa fa-check text-success" : (feature.available === -1 ? "fas fa-times text-danger" : "fas fa-minus text-warning")}></i>
                                                                                                {" "}{feature.bold ? <b>{feature.value}</b> : feature.value}
                                                                                                {feature.value.includes("Priority Support") && feature.available > 0 &&
                                                                                                    <>
                                                                                                        {" "}<OverlayTrigger
                                                                                                            placement="bottom"
                                                                                                            overlay={
                                                                                                                <Tooltip id={`tooltip-support-${plan.id}`}>
                                                                                                                    You will have access to a support area in the admin panel to open priority support tickets.
                                                                                                                </Tooltip>
                                                                                                            }
                                                                                                            >
                                                                                                            <i className="far fa-question-circle"></i>
                                                                                                        </OverlayTrigger>
                                                                                                    </>
                                                                                                }
                                                                                                {feature.value.includes("API Error Notifications") && feature.available > 0 &&
                                                                                                    <>
                                                                                                        {" "}<OverlayTrigger
                                                                                                            placement="bottom"
                                                                                                            overlay={
                                                                                                                <Tooltip id={`tooltip-support-${plan.id}`}>
                                                                                                                    LTIAAS will email you whenever there is an unexpected error in your account and give you access to priority support about that error.
                                                                                                                </Tooltip>
                                                                                                            }
                                                                                                            >
                                                                                                            <i className="far fa-question-circle"></i>
                                                                                                        </OverlayTrigger>
                                                                                                    </>
                                                                                                }
                                                                                                {feature.value.includes("Multiple Admins/Roles") && feature.available > 0 &&
                                                                                                    <>
                                                                                                        {" "}<OverlayTrigger
                                                                                                            placement="bottom"
                                                                                                            overlay={
                                                                                                                <Tooltip id={`tooltip-support-${plan.id}`}>
                                                                                                                    You will be able to invite other administrator users to our account that will have role-based authentication to registrations, billing, api settings, and more.
                                                                                                                </Tooltip>
                                                                                                            }
                                                                                                            >
                                                                                                            <i className="far fa-question-circle"></i>
                                                                                                        </OverlayTrigger>
                                                                                                    </>
                                                                                                }
                                                                                                {feature.value.includes("Core LTI Functionality") && feature.available > 0 &&
                                                                                                    <>
                                                                                                        {" "}<OverlayTrigger
                                                                                                            placement="bottom"
                                                                                                            overlay={
                                                                                                                <Tooltip id={`tooltip-support-${plan.id}`}>
                                                                                                                    Gives you access to basic authentication and launching using the LTI protocol and the idtoken endpoint.
                                                                                                                </Tooltip>
                                                                                                            }
                                                                                                            >
                                                                                                            <i className="far fa-question-circle"></i>
                                                                                                        </OverlayTrigger>
                                                                                                    </>
                                                                                                }
                                                                                                {feature.value.includes("MAU") && feature.available > 0 &&
                                                                                                    <>
                                                                                                        {" "}<OverlayTrigger
                                                                                                            placement="bottom"
                                                                                                            overlay={
                                                                                                                <Tooltip id={`tooltip-support-${plan.id}`}>
                                                                                                                    MAU = Monthly Active User. For example, if you have one user that uses your LTI tool every day, several times a day this month, we only bill you for one Monthly Active User (MAU). The first 5 MAU are free, after that there is $0.03 per additional MAU.
                                                                                                                </Tooltip>
                                                                                                            }
                                                                                                            >
                                                                                                            <i className="far fa-question-circle"></i>
                                                                                                        </OverlayTrigger>
                                                                                                    </>
                                                                                                }
                                                                                                {feature.value.includes("MAU") && feature.available === 0 &&
                                                                                                    <>
                                                                                                        {" "}<OverlayTrigger
                                                                                                            placement="bottom"
                                                                                                            overlay={
                                                                                                                <Tooltip id={`tooltip-support-${plan.id}`}>
                                                                                                                    MAU = Monthly Active User. For example, if you have one user that uses your LTI tool every day, several times a day this month, we only count that as one Monthly Active User (MAU). For the free trial, we limit the max MAU to 5.
                                                                                                                </Tooltip>
                                                                                                            }
                                                                                                            >
                                                                                                            <i className="far fa-question-circle"></i>
                                                                                                        </OverlayTrigger>
                                                                                                    </>
                                                                                                }
                                                                                                {feature.value.includes("Custom Domain") && feature.available > 0 &&
                                                                                                    <>
                                                                                                        {" "}<OverlayTrigger
                                                                                                            placement="bottom"
                                                                                                            overlay={
                                                                                                                <Tooltip id={`tooltip-support-${plan.id}`}>
                                                                                                                    You will be able to keep your branding and point your LTIAAS service to a custom domain of you choosing. For example: lti.yourcompany.com.
                                                                                                                </Tooltip>
                                                                                                            }
                                                                                                            >
                                                                                                            <i className="far fa-question-circle"></i>
                                                                                                        </OverlayTrigger>
                                                                                                    </>
                                                                                                }
                                                                                            </li>
                                                                                            {j+1 !== plan.features.length && <hr/>}
                                                                                        </>
                                                                                    )}
                                                                                </ul>
                                                                            </div>
                                                                            <div className={plan.name.includes("Self Hosted") ? "card-footer bg-light" : "card-footer bg-white"}>
                                                                                <button
                                                                                    type="button"
                                                                                    className={(tier !== plan.id)?"btn btn-block btn-outline-success":"btn btn-block btn-success"}
                                                                                    disabled={!plan.available}
                                                                                    onClick={() => {
                                                                                        if(tier === plan.id) {
                                                                                            setTier(null);
                                                                                            setFreeTier(false);
                                                                                            setTierClicked(false);
                                                                                        } else {
                                                                                            setFreeTier(plan.mau === 0);
                                                                                            setTier(plan.id);
                                                                                            setTierClicked(true);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {tier === plan.id && 
                                                                                        <>
                                                                                            <i className="fa fa-check"></i>
                                                                                        </>
                                                                                    }
                                                                                    {plan.available ? ((tier !== plan.id)?"Select":"Selected") : "Contact Us"}</button>    
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                                <div className="card" style={{display: (tier === null ? 'block' : 'none')}}>
                                                                    <div className="card-body">
                                                                        <a href="https://ltiaas.com/pricing" target="blank" className="btn btn-primary">
                                                                            Pricing<br/>
                                                                            Details
                                                                        </a>
                                                                        {"  or  "}
                                                                        <a href="https://ltiaas.com/contact-us" target="blank" className="btn btn-danger">
                                                                            Contact<br/>
                                                                            Us
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {plans.length > 0 && tier !== null ? (
                                        <>
                                            <div ref={planDiv} className="row justify-content-md-center">
                                                <p>Select your plan options below. Don't forget to take a look at our <i><a href="https://ltiaas.com/pricing" target="blank">detailed pricing page</a></i> for more information.</p>
                                            </div>
                                            <div className="card col-12" style={{display:"none"}}>
                                                <div className="card-header text-center"><h3>Start With The Core</h3></div>
                                                <div className="card-body">
                                                    <div className="row justify-content-md-center">
                                                        <div className="col col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                            <div className="mb-6 text-center">
                                                            </div>
                                                            <div className="card-columns mb-6 text-center">
                                                                {plans.map((plan,i) => 
                                                                    <>
                                                                        {plan.name === "Core" &&
                                                                        <div className="card" style={{marginLeft: "unset"}} key={i+plan.id}>
                                                                            <div className="card-header">
                                                                                <h2 className="my-0 font-weight-normal">
                                                                                    {plan.name}
                                                                                </h2>
                                                                                <h5 className="card-title">
                                                                                    {freeTier ? "Free" : (
                                                                                    <>
                                                                                        ${plan.price}
                                                                                        <small className="text-muted">
                                                                                            /{plan.paymentCycle}
                                                                                        </small>
                                                                                    </>
                                                                                    )}
                                                                                </h5>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <ul className="list-unstyled mt-3 mb-4">
                                                                                    {plan.features.map((feature, i) => 
                                                                                        <li key={i}><i className="fa fa-check text-success"></i> {feature}</li>
                                                                                    )}
                                                                                </ul>
                                                                            </div>
                                                                            <div className="card-footer bg-white">
                                                                                    <button type="button" className={(!selectedPlans.includes(plan.id))?"btn btn-block btn-outline-success":"btn btn-block btn-success"} onClick={() => {
                                                                                        if(!plan.required) {
                                                                                            toggleSelectedPlanClick(plan.id);
                                                                                        }
                                                                                    }}>{selectedPlans.includes(plan.id) && <><i className="fa fa-check"></i> </>}{(!selectedPlans.includes(plan.id))?"Select":(plan.required?"Required":"Selected")}</button>    
                                                                            </div>
                                                                        </div>}
                                                                    </>
                                                                )} 
                                                                <div className="card border-0" style={{marginLeft: "unset"}}>
                                                                    <div className="card-header border-0">
                                                                    </div>
                                                                    <div className="card-body border-0">
                                                                        <p>
                                                                            The core component of the plan is required.
                                                                            It enables basic authentication and launching using the LTI protocol.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card col-12">
                                                <div className="card-header text-center"><h3>Choose Your Add-Ons</h3></div>
                                                <div className="card-body">
                                                    <div className="row justify-content-md-center">
                                                        <p>Each Add-On enables a different feature of the LTI 1.3 standard. With LTIAAS you only pay for what you need.</p>
                                                        <div className="col col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                            <div className="card-columns mb-6 text-center">
                                                                {plans.map((plan,i) => 
                                                                    <>
                                                                        {plan.name !== "Core" && plan.name !== "Custom Domain Name" && plan.name !== "Self Hosted" &&
                                                                        <div className="card" style={{marginLeft: "unset"}} key={i+plan.id}>
                                                                            <div className="card-header">
                                                                                <h2 className="mt-0 font-weight-normal">
                                                                                    {plan.name}
                                                                                </h2>
                                                                                <h5 className="card-title">
                                                                                    {freeTier ? "Free" : (
                                                                                        <>
                                                                                        ${plan.price}
                                                                                        <small className="text-muted">
                                                                                            /{plan.paymentCycle}
                                                                                        </small>
                                                                                        </>
                                                                                    )}
                                                                                </h5>
                                                                            </div>
                                                                            <div className="card-body" dangerouslySetInnerHTML={{__html: plan.description}}></div>
                                                                            <div className="card-footer bg-white">
                                                                                    <button type="button" className={(!selectedPlans.includes(plan.id))?"btn btn-block btn-outline-success":"btn btn-block btn-success"} onClick={() => {
                                                                                        if(!plan.required) {
                                                                                            toggleSelectedPlanClick(plan.id);
                                                                                        }
                                                                                    }}>{selectedPlans.includes(plan.id) && <><i className="fa fa-check"></i> </>}{(!selectedPlans.includes(plan.id))?"Select":(plan.required?"Required":"Selected")}</button>    
                                                                            </div>
                                                                        </div>}
                                                                    </>
                                                                )
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ):(
                                        <>
                                            {(loading) && (
                                                <Loader text="loading plans..."></Loader>
                                            )}
                                        </>
                                    )}
                                    {selectedPlans.length > 0 && tier !== null && !selectedPlansError && !freeTier &&
                                        <div className="card mb-4 col-12">
                                            <div className="card-header text-center">
                                                <h3>Billing Details</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="form-group row">
                                                    <label className="col-lg-3 col-form-label mt-2 text-lg-right"><b>Country/Territory</b></label>
                                                    <div className="col-lg-9 mt-2">
                                                        {countryError !== null && 
                                                            <Alert type="danger" message={countryError} dismissible={true} onDismiss={() => setCountryError(null)}></Alert>
                                                        }
                                                        <select className="form-control" defaultValue={country} onChange={e => {
                                                            const countryCode = e.target.selectedOptions[0].value;
                                                            setCountry(countryCode);
                                                            setState("");
                                                            setCountryError(null);
                                                        }}>
                                                            <option value=''>-- Select a country --</option>
                                                            {Object.keys(countries).map((countryCode) => 
                                                                <option value={countryCode} key={countryCode}>{countries[countryCode].name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                {countries[country] && countries[country].states &&
                                                    <div className="form-group row">
                                                        <label className="col-lg-3 col-form-label mt-2 text-lg-right"><b>State/Province</b></label>
                                                        <div className="col-lg-9 mt-2">
                                                            {stateError !== null && 
                                                                <Alert type="danger" message={stateError} dismissible={true} onDismiss={() => setStateError(null)}></Alert>
                                                            }
                                                            <select className="form-control" defaultValue={state} onChange={e => {
                                                                setState(e.target.selectedOptions[0].value);
                                                                setStateError(null);
                                                            }}>
                                                                <option value=''>-- Select a state --</option>
                                                                {Object.keys(countries[country].states).map(stateCode => 
                                                                    <option value={stateCode} key={stateCode}>{countries[country].states[stateCode]}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="form-group row mb-0">
                                                    <label className="col-lg-3 col-form-label mt-2 text-lg-right">
                                                        <b>Credit/Debit Card</b>
                                                        <a className="ml-2" href="https://stripe.com/docs/security" target="blank">
                                                            <i className="fas fa-lock"></i>
                                                        </a>
                                                    </label>
                                                    <div className="col-lg-9 mt-2">
                                                        {cardError !== null && 
                                                            <Alert type="danger" message={cardError} dismissible={true} onDismiss={() => setCardError(null)}></Alert>
                                                        }
                                                        <div className="form-control">
                                                            <CardElement options={CARD_ELEMENT_OPTIONS}></CardElement>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9 mt-3">
                                                        <img alt="Accepted cards" src="/stripe-badge-transparent.png" style={{width: "300px"}}></img>
                                                    </div>
                                                </div>
                                                {errorMessage !== null && 
                                                    <div className="mt-2">
                                                        <Alert type="danger" message={errorMessage} dismissible={true} onDismiss={() => setErrorMessage(null)}></Alert>
                                                    </div>
                                                }
                                                {selectedPlans.length > 0 &&
                                                    <button className="btn btn-lg btn-block btn-primary" disabled={selectedPlans.length===0||processing?true:false} onClick={e => {
                                                        subcribe(e);
                                                    }}>{processing?(<Loader text="Please wait while subscription is being processed..."></Loader>):(<>Subscribe</>)}</button>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {selectedPlans.length > 0 && tier !== null && freeTier && !selectedPlansError &&
                                        <div className="card mb-4 col-12">
                                            <div className="card-header text-center">
                                                <h3>Create Free Account</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="alert alert-warning" role="alert">
                                                    Note: You are about to create a free account that has a limit of 500 LTI launches per month and 5 MAU.
                                                    {" "} It should be used for testing only. This account can be upgraded at any time after it is created.
                                                </div>
                                                {errorMessage !== null && 
                                                    <div className="mt-2">
                                                        <Alert type="danger" message={errorMessage} dismissible={true} onDismiss={() => setErrorMessage(null)}></Alert>
                                                    </div>
                                                }
                                                {selectedPlans.length > 0 &&
                                                    <button className="btn btn-lg btn-block btn-primary" disabled={selectedPlans.length===0||processing?true:false} onClick={e => {
                                                        subcribe_free(e);
                                                    }}>{processing?(<Loader text="Please wait while subscription is being processed..."></Loader>):(<>Subscribe</>)}</button>
                                                }
                                            </div>
                                        </div>
                                    }
                                </>
                            ):(
                                <Alert type="danger" message="Access Denied.  You are not the account owner." dismissible={false} ></Alert>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Plans;
